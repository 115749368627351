import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUrlParams } from 'utils';

import {
  withTolgee,
  Tolgee,
  I18nextPlugin,
  DevTools,
  BackendFetch,
} from '@tolgee/i18next';

const tolgee = Tolgee()
  .use(DevTools())
  .use(I18nextPlugin())
  .use(
    BackendFetch({
      prefix: 'https://cdn.tolg.ee/169e022cd4f94de8d5a5cb60b818da04',
    })
  )
  .init({
    zh_TW: () => import('./translation/zh_TW.json'),
    en: () => import('./translation/en.json'),
    jp: () => import('./translation/jp.json'),
  });

withTolgee(i18n, tolgee)
  .use(initReactI18next)
  .init({
    fallbackLng: 'zh_TW',
    lng: getUrlParams('locale') || 'zh_TW',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
